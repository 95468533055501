/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toSnakeCase } from '../../../../components/Centrum/helpers';
import { confirmTransaction, rejectTransction } from '../../../actions/TransactionActions';
import { Modal } from '../../../components/application/FluxApp';
import { Button } from '../../../components/button/Button';
import { currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { Icon } from '../../../components/icon/Icon';
import { FluxModal } from '../../../components/modal/FluxModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { API } from '../../../lib/API/Api';
import { AffiliateEndpoints, PlayerPromotionEndpoints, TransactionEndpoints } from '../../../lib/API/Endpoints';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { TransactionTypes } from '../../../lib/Enums/TransactionEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { footerValues } from '../../../lib/Helpers/Reports/Footer';
import { copyDataToClipboard } from '../../../lib/Helpers/StringHelper';
import { lang } from '../../../lib/Localization/language';
import './_search-player-transactions.scss';


const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'UserId',
            title: 'Player Id'
        },
        {
            name: 'Username',
            title: 'Username'
        },
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Id',
            title: 'Transaction Id',
            information: 'Our record'
        },
        {
            name: 'ExternalTransactionId',
            title: 'External Transaction Id',
            information: 'Transaction id recorded by provider'
        },
        {
            name: 'ProviderId',
            title: 'Provider',
            type: InputTypes.Select,
            nullable: true,
            //value: [191341, 191327],
            data: {
                source: 'myProviders',
                key: 'id',
                title: ['display'],
                parser: (result) => {
                    var results = [];
                    var group = 'Gaming';
                    result.result.map(x => {
                        x = toSnakeCase(x);
                        if (x.type == 128) group = 'Payment Provider';
                        results.push({
                            value: x.id, title: x.display, group: {
                                id: group,
                                title: group
                            }
                        });
                    });
                    return results;
                }
            }
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            exact: true,
            value: [0, 1],
            values: {
                0: 'Deposit',
                1: 'Withdraw',
                3: 'Bet',
                2: 'Win',
                4: 'Rollback',
                5: 'Processed',
                30: 'Inventory Expired'
            }
        },
        {
            name: 'Source',
            title: 'Source',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            multiFilterEnabled: true,
            exact: true,
            values: {
                'Bonus': 'Bonus',
                'Credit': 'Credit',
                'Admin': 'Admin',
                'Bonus Completed': 'Bonus Completed',
                'Bonus Credit': 'Bonus Credit',
                'Rollback': 'Rollback',
                'CryptoWithdrawRequest': 'CryptoWithdrawRequest',
                'Promotion': 'Promotion',
                'Balance Reset': 'Balance Reset'
            }
        },
        {
            name: 'Status',
            title: 'Status',
            type: InputTypes.Select,
            nullable: true,
            exact: true,
            value: 1,
            values: {
                0: 'Pending',
                1: 'Confirmed',
                2: 'Cancelled',
            }
        },
        {
            name: 'PromotionCheck',
            title: 'Promotion',
            type: InputTypes.MultipleSelect,
            exact: true,
            nullable: true,
            value: null,
            values: {
                'All': null,
                'Only Promotions': true,
                'Except Promotions': false
            }
        },
        {
            name: 'Email',
            title: 'Email',
            hidden: true
        },
    ]
};

const resultTransactionInformation = {
    fields: [
        {
            name: 'TransactionDate',
            title: 'Time',
            type: InputTypes.DateTime,
            multiLine: true
        },
        {
            name: 'UpdateDate',
            title: 'Update Time',
            type: InputTypes.DateTime,
            multiLine: true,
            hidden: true
        },
        {
            name: 'Id',
            title: 'Id'
        },
        {
            name: 'ParentTransactionId',
            title: 'Parent'
        },
        {
            name: 'ExternalTransactionId',
            title: 'External Transaction Id'
        },
        {
            name: 'Type',
            title: 'Type',
            type: InputTypes.Select,
            values: TransactionTypes
        }, {
            name: 'source',
            title: 'Source',
            render: (val, row) => {
                return <flex className='vertical'>
                    <div className='elipsis'>{lang(row.Source)}</div>
                    {row.PromotionName && <div className='elipsis' title={lang(row.PromotionName)}>{lang(row.PromotionName)}</div>}
                </flex>;
            }
        },
        {
            name: 'Provider',
            title: 'Provider'
        },
        {
            name: 'Username'
        },
        {
            name: 'Affiliate'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'OperatorUser',
            title: 'Admin'
        },
        {
            name: 'Notes',
            title: 'Notes',
            render: (val) => {
                return <p className='multiline' title={val}>{val}</p>;
            },
            optional: true
        },
        {
            name: 'BonusWallet',
            title: 'Bonus Wallet',
            type: InputTypes.Bool
        },
        {
            name: 'Amount',
            title: 'Amount',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.Currency);
            },
            //its not currency values they are beign shown as Deposit XXX or Withdrarw XXX for per multiple customers request
            renderFooter: (val) => footerValues(val),
            exportFooter: (val) => footerValues(val, true)
        },
        {
            name: 'BalanceAfter',
            title: 'Balance',
            type: InputTypes.Number,
            render: (val, row) => {
                return currency(val, 2, row.Currency);
            }
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Status == 1) {
            return 'transaction-confirmed';
        }
        if (row.Status == 2) {
            return 'transaction-cancel';
        }
        if (row.Type == 4) {
            return 'transaction-rollback';
        }
        return className;
    }
};

export const SearchPlayerTransactions = (props) => {
    const [title, setTitle] = useState('Search player transactions');
    const [actions, setActions] = useState(null);
    useEffect(() => {
        if (props.pending && props.deposits) {
            setActions(ActionTypes.SearchPendingDeposits);
            return;
        }
        if (props.pending && props.withdraws) {
            setActions(ActionTypes.SearchPendingWithdraws);
            return;
        }
        if (props.playerId) {
            setActions(ActionTypes.SearchPlayerTransactions);
            return;
        }
        if (props.affiliate) {
            setActions([]);
            setTitle(<React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search player transactions')}</span></React.Fragment>);
            return;
        }
        setActions(ActionTypes.SearchTransactions);
    }, []);


    const endpoint = () => {
        if (props.affiliate) {
            return `${AffiliateEndpoints.Transactions}/${props.affiliate.Id}`;
        }
        return TransactionEndpoints.Search;
    };



    const onActionClicked = (action, transaction, selection) => {
        var data = selection.tableContext.getData();
        switch (action.type) {
            case ActionTypes.ShowTransactionsData:
                var transactionData = <pre>{transaction.Data}</pre>;
                if (transaction.Data && transaction.Data.indexOf('{') == 0) {
                    try {
                        var converted = JSON.parse(transaction.Data);
                        var elements = Object.keys(converted).map(x => {
                            return <flex className='vertical' key={x}>
                                <span className='bold'>{lang(x)}</span>
                                <span className=''>{converted[x].toString()}</span>
                            </flex>;
                        });
                        transactionData = <flex className='vertical gap-5'>
                            {elements}
                        </flex>;
                    } catch (err) {
                        //
                    }
                }

                if (!transaction.Data) {
                    transactionData = <FluxNotice type='information' title='No information' description='There is no information for this transaction' />;
                }
                Modal.open(<FluxModal title='Transaction information' footer={<flex className='center gap-10'>
                    {transaction.Data && <Button title='Copy to clipboard' onClick={() => copyDataToClipboard(transaction.Data)} />}
                    <Button title='Close' className={'success'} onClick={() => Modal.close()} />
                </flex>}>
                    {transactionData}
                </FluxModal>);
                break;
            case ActionTypes.PlayerProfile:
                gotoProfile(transaction.UserId);
                break;
            case ActionTypes.ConfirmTransaction:
                API.post(PlayerPromotionEndpoints.GetPlayerPromotion(transaction.UserId)).then((res) => {
                    confirmTransaction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data, promotion: res.result, playerId: transaction.UserId, currency: transaction.Currency }, transaction.Type, () => {
                        data = data.filter(x => x.Id != transaction.Id);
                        selection.tableContext.setData(data);
                    });
                });
                break;
            case ActionTypes.RejectTransaction:
                rejectTransction({ id: transaction.Id, amount: transaction.Amount, data: transaction.Data, currency: transaction.Currency }, transaction.Type, () => {
                    data = data.filter(x => x.Id != transaction.Id);
                    selection.tableContext.setData(data);
                });
                break;
            default:
                console.log(action.type);
                break;
        }
    };


    if (!actions) return <></>;

    return <FluxSearch
        className='search-player-transactions-table'
        title={title}
        model={model}
        resultModel={resultTransactionInformation}
        endpoint={endpoint()}
        searchOnStart={props.pending}
        actions={actions}
        onActionClicked={onActionClicked.bind(this)}
        onPrepareModel={(model) => {
            var copy = { ...model };
            if (props.payment) {
                var providerField = copy.fields.find(x => x.name == 'ProviderId');
                providerField.data.source = 'providers/payment';
            }
            if (props.playerId) {
                var playerField = copy.fields.find(x => x.name == 'UserId');
                playerField.hidden = true;
                playerField.value = props.playerId;
                copy.fields.find(x => x.name == 'Username').hidden = true;
                copy.name = 'transactions-with-player-id';
            }

            if (props.affiliate) {
                copy.name = 'transactions-of-affiliate';
                return copy;
            }

            if (props.pending) {
                copy.fields = copy.fields.filter(x => x.name != 'Status');
                copy.fields.push({
                    name: 'Status',
                    value: '0',
                    exact: true,
                    and: true,
                    hidden: true
                });
            }

            if (props.deposits) {
                copy.fields.find(x => x.name == 'Type').value = '0';
                copy.fields.find(x => x.name == 'Type').hidden = true;
                copy.fields = copy.fields.filter(x => x.name != 'TransactionDate');

                copy.name = `${props.pending ? 'pending' : ''}deposit-transactions`;
            }
            if (props.withdraws) {
                copy.fields.find(x => x.name == 'Type').value = 1;
                copy.fields.find(x => x.name == 'Type').hidden = true;
                copy.fields = copy.fields.filter(x => x.name != 'TransactionDate');

                copy.name = `${props.pending ? 'pending' : ''}withdraw-transactions`;
            }
            return copy;
        }}
    />;
};


SearchPlayerTransactions.propTypes = {
    affiliate: PropTypes.object,
    playerId: PropTypes.number,
    payment: PropTypes.bool,
    pending: PropTypes.bool,
    deposits: PropTypes.bool,
    withdraws: PropTypes.bool
};