/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from '../../../../components/Controls/DateTime/DateTime';
import { giveBonus, rejectPendingPromotion } from '../../../actions/BonusActions';
import { FluxAction } from '../../../components/application/FluxAction';
import { Modal } from '../../../components/application/FluxApp';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { ErrorModal } from '../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { Popper } from '../../../components/notification/FluxPopper';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { API } from '../../../lib/API/Api';
import { PlayerEndpoints, PromotionEndpoints } from '../../../lib/API/Endpoints';
import { BonusType } from '../../../lib/Enums/BonusEnums';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';

const searchModel = {
    name: 'search-players-with-pending-promotoin',
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: '/api/promotions/list',
                parameters: {},
                parser: (result) => {
                    var results = [];
                    result.result.filter(x => x.Enabled).map(x => {
                        if (x.Type !== 1 && x.Type !== 2 && x.Type !== 3 && x.Type !== 8) return false;


                        results.push({
                            title: x.Name, value: x.Id,
                            group: {
                                id: x.Type,
                                title: BonusType[x.Type],
                                data: x
                            }
                        });
                    });
                    return results;
                }
            }
        }
    ]
};

export const ResultModel = {
    name: 'search-players-with-pending-promo-result',
    fields: [
        {
            name: 'EntryDate',
            title: 'Entry Date',
            type: InputTypes.DateTime,
            render: (val) => {
                return <DateTime date={val} />;
            }
        },
        {
            name: 'Id',
            title: 'Id'
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode',
            title: 'Player Code',
            hidden: true
        },
        {
            name: 'Group',
            title: 'Player Group'
        },
        {
            name: 'PromotionName',
            title: 'Promotion'
        },
        {
            name: 'Balance',
            title: 'Balance',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        },
        {
            name: 'BonusBalance',
            title: 'Bonus Balance',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};

export const SearchPendingPromotions = () => {
    const [promotions, setPromotions] = useState(null);
    const user = useSelector((state) => state.user).loginInformation;

    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;


    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            let filtered = result.result.filter(x => x.OperatorId == window.user.OperatorId && x.Type != 7);
            setPromotions(filtered);
        });
    }, []);

    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };

    const onActionClicked = (action, consumedPromotionInformation, context) => {

        switch (action.type) {
            case ActionTypes.ConfirmPendingPromotion:
                var promotion = promotions.find(x => x.Id == consumedPromotionInformation.PromotionId);
                if (!promotion) return;
                if (promotion.Type == 5) {
                    Modal.open(<ErrorModal title='Unable to confirm modal'>
                        <FluxNotice type='warning' title='Promotion Type is not allowed!' description={'Free spin promotions can not be confirmed, please check promotion CMS settings!'} />
                    </ErrorModal>);
                    return;
                }
                if (promotion.Type == 7) {
                    Modal.open(<ErrorModal title='Unable to confirm modal'>
                        <FluxNotice type='warning' title='Promotion Type is not allowed!' description={'Insurance bonus is an automated bonus calculated at sports betting ticket results!'} />
                    </ErrorModal>);
                    return;
                }
                if (promotion.Type == 10) {
                    Modal.open(<ErrorModal title='Unable to confirm modal'>
                        <FluxNotice type='warning' title='Promotion Type is not allowed!' description={'Players can not request for bet booster bonus!'} />
                    </ErrorModal>);
                    return;
                }
                if (promotion.Type == 11) {
                    Modal.open(<ErrorModal title='Unable to confirm modal'>
                        <FluxNotice type='warning' title='Promotion Type is not allowed!' description={'Multiplier bonus is an automated bonus calculated at sports betting ticket results!'} />
                    </ErrorModal>);
                    return;
                }
                giveBonus({
                    id: consumedPromotionInformation.PlayerId,
                    selectedPromotion: promotion,
                }, () => {
                    var data = context.tableContext.getData();
                    data = data.filter(x => x.Id != consumedPromotionInformation.Id);
                    context.tableContext.setData(data);
                    Popper.pop('information', 'Bonus given to player');
                });
                break;
            case ActionTypes.RejectPendingPromotion:
                rejectPendingPromotion([consumedPromotionInformation], () => {
                    var data = context.tableContext.getData();
                    data = data.filter(x => x.Id != consumedPromotionInformation.Id);
                    context.tableContext.setData(data);
                    Popper.pop('information', 'Player bonus rejected');

                });
                break;
            case ActionTypes.PlayerProfile:
                gotoProfile(consumedPromotionInformation.PlayerId);
                break;
        }

    };

    return <FluxAction action={ActionTypes.SearchPlayersWithBonus} checkActive={false}>
        <FluxSearch
            model={searchModel}
            resultModel={ResultModel}
            endpoint={PlayerEndpoints.SearchPlayersPendingPromotions}
            actions={ActionTypes.SearchPlayersPendingPromotions}
            title={'Pending Promotions'}
            searchOnStart
            exportToExcel={isExportEnabled('ExportPlayers')}
            exportAllData={isExportEnabled('ExportPlayers')}
            onActionClicked={onActionClicked.bind(this)} />
    </FluxAction>;
};
