import React, { useEffect, useState } from 'react';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { API } from '../../../../v2/Lib/Api/Api';
import { useSelector } from 'react-redux';
import { Modal } from '../../../components/application/FluxApp';
import { FluxForm } from '../../../components/form/FluxForm';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Popper } from '../../../components/notification/FluxPopper';
import { lang } from '../../../lib/Localization/language';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { movePlayerToAffiliate, removePlayerFromAffiliate } from '../../../actions/AffiliateActions';
import { movePlayerToRetail, removePlayerFromRetail } from '../../../actions/RetailActions';
import { FluxTab } from '../../../components/tab/FluxTab';
import { PlayerCenter } from '../PlayerShortcuts/PlayerCenter';
import { FluxAction } from '../../../components/application/FluxAction';
import { PlayerVerifications } from '../PlayerVerifications/PlayerVerifications';
import { editPlayer } from '../../../actions/PlayerActions';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ModelPlayerSummary, ModelPlayerSummaryWithBalances, ModelSearchNewPlayer, ModelSearchPlayer, ModelSearchPlayerWithBalance } from '../../../lib/Models/PlayerModels';
import { getHistory } from '../../../../routers/AppRouterFlux';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import ctrlKeyManager from '../../../../state/services/CtrlKeyManager';


export const PlayerOperations = () => {
    const [location, setLocation] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const user = useSelector((state) => state.user).loginInformation;

    useEffect(() => {
        setLocation(window.location.search);
    }, []);

    useEffect(() => {
        if (location !== window.location.search) {
            setLocation(window.location.search);
            const urlParams = new URLSearchParams(window.location.search);
            const id = parseFloat(urlParams.get('id'));
            if (isNaN(id)) return;
            setTabIndex(0);
        }
    });


    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };

    const onActionClicked = (action, player) => {
        switch (action.type) {
            case ActionTypes.PlayerProfile:
                {
                    ctrlKeyManager.init();

                    const isCtrlPressed = ctrlKeyManager.getCtrlKeyStatus();
                    if (isCtrlPressed) {
                        const url = `/players/search?&id=${player.Id}`;
                        window.open(url, '_blank');
                    } else {
                        getHistory().push(`/players/search?&id=${player.Id}`);
                        setTabIndex(0);
                    }
                }
                break;
            case ActionTypes.UpdatePlayer:
                editPlayer(player.Id);
                break;
            case ActionTypes.ChangePlayerPassword:
                Modal.open(<FluxModal title='Update password'>
                    <FluxForm
                        data={player}
                        endpoint={PlayerEndpoints.ChangePassword}
                        model={{
                            fields: [
                                { name: 'Password', title: 'Password', type: 'password' },
                                { name: 'PasswordConfirm', title: 'Confirm Password', type: 'password' },
                            ]
                        }}
                        onCancel={() => Modal.close()}
                        onSubmitComplete={() => {
                            Modal.close();
                            Popper.pop(NotificationType.Success, lang('Password changed.'));
                        }}
                    />
                </FluxModal>);
                break;
            case ActionTypes.ToggleEnablePlayer:
                Modal.open(<ConfirmationModal title={'Please confirm'} onConfirm={() => {
                    API.post(`${PlayerEndpoints.ToggleEnablePlayer}/${player.Id}`, player).then(() => {
                        Popper.pop('information', player.Enabled ? 'Player disabled' : 'Player enabled');
                    });
                }}>
                    <p>{lang('Do you want to %1?', [player.Enabled ? 'Deactivate player' : 'Activate player'])}</p>
                </ConfirmationModal>);
                break;
            case ActionTypes.MovePlayerToRetail:
                if (player.RetailName != null) {
                    removePlayerFromRetail(player);
                } else {
                    movePlayerToRetail(player);
                }
                break;
            case ActionTypes.AddPlayerToAffiliate:
                if (player.Affiliate != null) {
                    removePlayerFromAffiliate(player, () => {
                        player.Affiliate = '';
                    });
                } else {
                    movePlayerToAffiliate(player, () => {
                        player.Affiliate = '--';
                    });
                }
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    const onRenderPlayerSearchActions = (action, selection) => {
        if (selection.length == 0) action;
        var item = selection[0];
        if (item === undefined) return action;
        switch (action.type) {
            case ActionTypes.ToggleEnablePlayer:
                return item.Enabled ? { caption: lang('Deactivate Player'), icon: 'disable', enabled: true } : { caption: lang('Activate Player'), icon: 'enable', enabled: true };
            case ActionTypes.MovePlayerToRetail:
                return item.RetailName != null ? { caption: lang('Remove from Retail'), enabled: true } : { caption: lang('Move To Retail'), enabled: true };
            case ActionTypes.AddPlayerToAffiliate:
                return item.Affiliate != null ? { caption: lang('Remove from Affiliate'), enabled: true } : { caption: lang('Move To Affiliate'), enabled: true };
        }
        return action;
    };

    return <FluxTab
        className={'gap-10 fx-shadow fx-radius rounded-buttons'}
        buttons={
            [
                {
                    title: 'Player Center'
                },
                {
                    title: 'Detailed Search'
                },
                {
                    title: 'New Players'
                },
                {
                    title: 'Player Verifications',
                    action: ActionTypes.SearchPlayerVerifications
                },
                {
                    title: 'Search Player With Balance',
                    action: ActionTypes.SearchPlayerDetailWithBalance
                }
                /*,
                {
                    title: 'Search Player Activities',
                    action: ActionTypes.SearchPlayerActivity
                }
                */
            ]
        }
        key={tabIndex}
        selected={tabIndex}
        onChange={(index) => setTabIndex(index)}
    >
        <PlayerCenter />
        <FluxAction action={ActionTypes.SearchPlayer}>
            <FluxSearch
                model={ModelSearchPlayer}
                resultModel={ModelPlayerSummary}
                endpoint={PlayerEndpoints.Search}
                actions={ActionTypes.SearchPlayer}
                title={'Search players'}
                exportToExcel={isExportEnabled('ExportPlayers')}
                exportAllData={isExportEnabled('ExportPlayers')}
                onRenderAction={onRenderPlayerSearchActions.bind(this)}
                onActionClicked={onActionClicked.bind(this)} />
        </FluxAction>
        <FluxAction action={ActionTypes.SearchNewPlayers} checkActive={false}>
            <FluxSearch
                model={ModelSearchNewPlayer}
                resultModel={ModelPlayerSummary}
                endpoint={PlayerEndpoints.Search}
                actions={ActionTypes.SearchPlayer}
                title={'New players'}
                exportToExcel={isExportEnabled('ExportPlayers')}
                exportAllData={isExportEnabled('ExportPlayers')}
                onRenderAction={onRenderPlayerSearchActions.bind(this)}
                onActionClicked={onActionClicked.bind(this)} />
        </FluxAction>
        <FluxAction action={ActionTypes.SearchPlayerVerifications} checkActive={false}><PlayerVerifications /></FluxAction>
        <FluxAction action={ActionTypes.SearchPlayerDetailWithBalance} checkActive={false}>
            <FluxSearch
                model={ModelSearchPlayerWithBalance}
                resultModel={ModelPlayerSummaryWithBalances}
                endpoint={PlayerEndpoints.SearchPlayerDetailWithBalance}
                actions={ActionTypes.SearchPlayerDetailWithBalance}
                title={'Search Player With Balance'}
                exportToExcel={isExportEnabled('ExportPlayers')}
                exportAllData={isExportEnabled('ExportPlayers')}
                onRenderAction={onRenderPlayerSearchActions.bind(this)}
                onActionClicked={onActionClicked.bind(this)} />
        </FluxAction>
        {/* <FluxAction action={ActionTypes.SearchPlayerActivity} checkActive={false}><SearchPlayerActivity /></FluxAction> */}
    </FluxTab >;
};
