/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from '../../../../components/Controls/DateTime/DateTime';
import { bulkRemovePromotion, completeBonus } from '../../../actions/BonusActions';
import { FluxAction } from '../../../components/application/FluxAction';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { API } from '../../../lib/API/Api';
import { PlayerEndpoints, PromotionEndpoints } from '../../../lib/API/Endpoints';
import { BonusType } from '../../../lib/Enums/BonusEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';

const searchModel = {
    name: 'search-players-promo-progres-search',
    fields: [
        {
            name: 'OperatorId',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'ConsumedDate',
            title: 'Start Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'PromotionId',
            title: 'Promotion',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: '/api/promotions/list',
                parameters: {},
                parser: (result) => {
                    var results = [];
                    result.result.filter(x => x.Enabled).map(x => {
                        if (x.Type !== 1 && x.Type !== 2 && x.Type !== 3 && x.Type !== 8) return false;


                        results.push({
                            title: x.Name, value: x.Id,
                            group: {
                                id: x.Type,
                                title: BonusType[x.Type],
                                data: x
                            }
                        });
                    });
                    return results;
                }
            }
        },
        {
            name: 'Status',
            title: 'Status',
            type: InputTypes.Select,
            value: 0,
            nullable: true,
            values: {
                0: 'In Progress',
                1: 'Completed',
                3: 'Waiting for confirmation'
            }
        },
    ]
};

export const ResultModel = {
    name: 'search-players-promo-progres-result',
    fields: [
        {
            name: 'ConsumedDate',
            title: 'Start Date',
            type: InputTypes.Bool,
            render: (val, row) => {
                return <DateTime date={row.ConsumedDate} />;
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Username'
        },
        {
            name: 'PlayerCode',
            title: 'Player Code',
            hidden: true
        },
        {
            name: 'Group',
            title: 'Player Group'
        },
        {
            name: 'PromotionName',
            title: 'Promotion'
        },
        {
            name: 'TurnoverMultiplier',
            title: 'Rollover Multiplier'
        },
        {

            name: 'PlayerBalance',
            title: 'Starting Balance',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {

            name: 'Amount',
            title: 'Bonus Amount',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        },
        {
            name: 'TotalBet',
            title: 'Total Stake',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SportsBets',
            title: 'Bets'
        },
        {
            name: 'TotalWin',
            title: 'Win',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CompletedPayout',
            title: 'Payout',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        },
        {
            name: 'Balance',
            title: 'Balance',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusBalance',
            title: 'Bonus Balance',
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};

export const SearchPlayersWithBonus = () => {
    const [promotions, setPromotions] = useState(null);
    const user = useSelector((state) => state.user).loginInformation;

    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;


    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            let filtered = result.result.filter(x => x.OperatorId == window.user.OperatorId && x.Type != 7);
            setPromotions(filtered);
        });
    }, []);

    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };

    const onActionClicked = (action, consumedPromotionInformation) => {

        switch (action.type) {
            case ActionTypes.CompleteBonus:
                var promotion = promotions.find(x => x.Id == consumedPromotionInformation.ActivePromotion);
                completeBonus({
                    playerId: consumedPromotionInformation.PlayerId,
                    balance: consumedPromotionInformation.Balance,
                    bonusBalance: consumedPromotionInformation.BonusBalance,
                    currency: consumedPromotionInformation.Currency,
                    consumedPromotionId: consumedPromotionInformation.ActivePromotion,
                    promotion: promotion,
                    totalBet: consumedPromotionInformation.TotalBet,
                    bonusWallet: consumedPromotionInformation.BonusWallet,
                    amount: consumedPromotionInformation.Amount
                }, () => {

                });
                break;
            case ActionTypes.RemoveBonusFromPlayer:
                bulkRemovePromotion([consumedPromotionInformation], () => {

                });
                break;
            case ActionTypes.PlayerProfile:
                gotoProfile(consumedPromotionInformation.PlayerId);
                break;
        }

    };

    return <FluxAction action={ActionTypes.SearchPlayersWithBonus} checkActive={false}>
        <FluxSearch
            model={searchModel}
            resultModel={ResultModel}
            endpoint={PlayerEndpoints.SearchPlayerBonusProgress}
            actions={ActionTypes.SearchPlayersWithBonus}
            title={'Player bonus progress'}
            exportToExcel={isExportEnabled('ExportPlayers')}
            exportAllData={isExportEnabled('ExportPlayers')}
            onActionClicked={onActionClicked.bind(this)} />
    </FluxAction>;
};
