import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from '../../../../v2/Lib/Api/Api';
import { movePlayerToAffiliate, removePlayerFromAffiliate } from '../../../actions/AffiliateActions';
import { editPlayer } from '../../../actions/PlayerActions';
import { movePlayerToRetail, removePlayerFromRetail } from '../../../actions/RetailActions';
import { FluxAction } from '../../../components/application/FluxAction';
import { Modal } from '../../../components/application/FluxApp';
import { FluxForm } from '../../../components/form/FluxForm';
import { Icon } from '../../../components/icon/Icon';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxModal } from '../../../components/modal/FluxModal';
import { NotificationType } from '../../../components/notification/FluxNotification';
import { Popper } from '../../../components/notification/FluxPopper';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { AffiliateEndpoints, PlayerEndpoints, RetailEndpoints } from '../../../lib/API/Endpoints';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { lang } from '../../../lib/Localization/language';
import { ModelAffiliatePlayer, ModelPlayerSummary, ModelRetailPlayer, ModelSearchAffiliatePlayers, ModelSearchPlayer, ModelSearchRetailPlayers } from '../../../lib/Models/PlayerModels';


export const SearchPlayers = (props) => {
    const [title, setTitle] = useState('Search players');
    const [endpoint, setEndpoint] = useState(PlayerEndpoints.Search);
    const [actions, setActions] = useState(ActionTypes.SearchPlayer);
    const user = useSelector((state) => state.user).loginInformation;
    const isAffiliate = user.AffiliateId > 0;


    useEffect(() => {
        if (props.affiliate) {
            setTitle(<React.Fragment><span>{lang('Affiliate')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.affiliate.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Players')}</span></React.Fragment>);

            // this component is used by affiliate
            if (user.AffiliateId > 0) {
                setEndpoint(AffiliateEndpoints.ListAffiliatePlayers);
            }
            return;
        }
        if (props.retail) {
            setTitle(<React.Fragment><span>{lang('Retail')}</span><Icon icon='chevron-right' type='fa-light' /><span>{props.retail.Name}</span><Icon icon='chevron-right' type='fa-light' /><span>{lang('Search Players')}</span></React.Fragment>);
            setEndpoint(`${RetailEndpoints.ListRetailPlayers}/${props.retail.Id}`);
            setActions(ActionTypes.ListRetailPlayers);
            return;
        }
    }, []);

    const prepareModel = () => {
        var copy = { ...ModelSearchPlayer };
        copy.name = 'search-player';
        if (props.affiliate) {
            copy = { ...ModelSearchAffiliatePlayers };
            copy.fields = copy.fields.filter(x => x.name != 'AffiliateId');
            copy.fields.push({ name: 'AffiliateId', value: props.affiliate.Id, exact: true, and: true, hidden: true });
            copy.name = 'search-players' + props.affiliate.Id;
            return copy;
        }

        if (props.retail) {
            copy = { ...ModelSearchRetailPlayers };
            copy.fields = copy.fields.filter(x => x.name != 'RetailId');
            copy.fields.push({ name: 'RetailId', value: props.retail.Id, exact: true, and: true, hidden: true });
            copy.name = 'search-players' + props.retail.Id;
            return copy;
        }

        return copy;
    };

    const prepareResultModel = () => {
        var copy = { ...ModelPlayerSummary };
        if (props.affiliate) {
            copy = { ...ModelAffiliatePlayer };
            copy.fields = copy.fields.filter(x => x.name != 'Affiliate');
        }

        if (props.retail) {
            copy = { ...ModelRetailPlayer };
        }

        return copy;
    };

    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };

    const onActionClicked = (action, player) => {
        switch (action.type) {
            case ActionTypes.PlayerProfile:
                gotoProfile(player.Id, isAffiliate);
                break;
            case ActionTypes.UpdatePlayer:
                editPlayer(player.Id);
                break;
            case ActionTypes.ChangePlayerPassword:
                Modal.open(<FluxModal title='Update password'>
                    <FluxForm
                        data={player}
                        endpoint={PlayerEndpoints.ChangePassword}
                        model={{
                            fields: [
                                { name: 'Password', title: 'Password', type: 'password' },
                                { name: 'PasswordConfirm', title: 'Confirm Password', type: 'password' },
                            ]
                        }}
                        onCancel={() => Modal.close()}
                        onSubmitComplete={() => {
                            Modal.close();
                            Popper.pop(NotificationType.Success, lang('Password changed.'));
                        }}
                    />
                </FluxModal>);
                break;
            case ActionTypes.ToggleEnablePlayer:
                Modal.open(<ConfirmationModal title={'Please confirm'} onConfirm={() => {
                    API.post(`${PlayerEndpoints.ToggleEnablePlayer}/${player.Id}`, player).then(() => {
                        Popper.pop('information', player.Enabled ? 'Player disabled' : 'Player enabled');
                    });
                }}>
                    <p>{lang('Do you want to %1?', [player.Enabled ? 'Deactivate player' : 'Activate player'])}</p>
                </ConfirmationModal>);
                break;
            case ActionTypes.MovePlayerToRetail:
                if (player.RetailName != null) {
                    removePlayerFromRetail(player);
                } else {
                    movePlayerToRetail(player);
                }
                break;
            case ActionTypes.AddPlayerToAffiliate:
                if (player.Affiliate != null) {
                    removePlayerFromAffiliate(player, () => {
                        player.Affiliate = '';
                    });
                } else {
                    movePlayerToAffiliate(player, () => {
                        player.Affiliate = '--';
                    });
                }
                break;
            default:
                console.log(action.type);
                break;
        }
    };

    const onRenderPlayerSearchActions = (action, selection) => {
        if (selection.length == 0) action;
        var item = selection[0];
        if (item === undefined) return action;
        switch (action.type) {
            case ActionTypes.ToggleEnablePlayer:
                return item.Enabled ? { title: lang('Deactivate Player'), icon: 'disable', enabled: true } : { title: lang('Activate Player'), icon: 'enable', enabled: true };
            case ActionTypes.MovePlayerToRetail:
                return item.RetailName != null ? { title: lang('Remove from Retail'), enabled: true } : { title: lang('Move To Retail'), enabled: true };
            case ActionTypes.AddPlayerToAffiliate:
                return item.Affiliate != null ? { title: lang('Remove from Affiliate'), enabled: true } : { title: lang('Move To Affiliate'), enabled: true };
        }
        return action;
    };

    return <FluxAction action={user.AffiliateId > 0 ? ActionTypes.ListAffiliatePlayers : ActionTypes.SearchPlayer} checkActive={false}>
        <FluxSearch
            model={prepareModel()}
            resultModel={prepareResultModel()}
            endpoint={endpoint}
            actions={actions}
            title={title}
            exportToExcel={isExportEnabled('ExportPlayers')}
            exportAllData={isExportEnabled('ExportPlayers')}
            onRenderAction={onRenderPlayerSearchActions.bind(this)}
            onActionClicked={onActionClicked.bind(this)} />
    </FluxAction>;
};

SearchPlayers.propTypes = {
    affiliate: PropTypes.object,
    retail: PropTypes.object
};