import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../../components/application/FluxApp';
import { ErrorModal } from '../../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { API } from '../../../../lib/API/Api';
import { Endpoints } from '../../../../../components/Application/Application';


var __socketContext = null;

export const getMonitorSocket = () => {
    return __socketContext;
};

export const getWSUri = (source, operatorId, relUri) => {
    var uri = new URL(relUri, window.location.href);

    source = source.replace('{operatorId}', operatorId);
    return (uri.protocol === 'https:' ? 'wss' : 'ws') + `://${source}` + uri.search;

    //return 'ws://10:2121/ws' + uri.search;
};


var pingInterval = null;



export const BetMonitorSocketContext = createContext(null);



var handlers = null;



export const BetMonitorSocket = (props) => {
    const user = useSelector((state) => state.user.loginInformation);

    const [socket, createSocket] = useState(null);
    //Endpoints are being fetched from backend from now on. if you need to override, 
    //set `endpoint` prop where you call this component
    var endpoint = props.endpoint;
    const [retryCount, setRetryCount] = useState(0);


    const [context, setContext] = useState({
        eventHandlers: (newHandlers) => {
            handlers = newHandlers;
        }
    });


    const dispatch = useDispatch();


    __socketContext = {
        send: send,
        message: (a, b) => message(a, b)
    };

    useEffect(() => {
        if (!endpoint) {
            API.post(`${Endpoints.GetTickerUrl}`, { type: props.type }).then((result) => {
                endpoint = result.result;
                connect();
            });
        } else {
            connect();
        }
        return () => {
            clearInterval(pingInterval);
        };
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.onopen = () => {
            if (pingInterval) clearInterval(pingInterval);
            pingInterval = setInterval(() => {
                send('p');
            }, 10000);
            authenticate();
        };
        socket.onerror = () => {
            clearInterval(pingInterval);
            setTimeout(() => {
                handleSocketExceptions();
            }, 1000);
        };
        socket.onclose = () => {
            clearInterval(pingInterval);
            setTimeout(() => {
                handleSocketExceptions();
            }, 1000);
        };
        socket.onmessage = onMessageReceived.bind(this);
    }, [socket]);

    useEffect(() => {
        if (!retryCount) return;
        let timeOut = retryCount * 1000;
        setTimeout(() => {
            connect();
        }, timeOut);
    }, [retryCount]);

    const handleSocketExceptions = () => {
        dispatch({ type: 'CONNECTION_LOST', payload: null });
        setRetryCount(!retryCount ? 1 : retryCount + 1);
    };
    const connect = () => {
        if (!endpoint) return;
        createSocket(new WebSocket(getWSUri(endpoint, user.UserPrivileges.PointOfView, `/?&token=${user.Token}`)));
    };

    const authenticate = () => {
        message('authenticate', user.lastToken);
    };

    const message = (type, data) => {
        const msg = JSON.stringify({ type: type, data: data });
        send(msg);
    };

    const send = (msg) => {
        if (!socket) return;
        if (socket.readyState != 1) return;
        try {
            socket.send(msg);
        } catch (err) {
            //
            console.log(err);
        }
    };

    const onMessageReceived = (raw) => {
        if (raw.data == 'p') return;
        const message = JSON.parse(raw.data);
        dispatch({
            type: 'ON_SOCKET_DATA', payload: {
                type: props.type,
                target: message.type,
                payload: message.payload
            }
        });
        switch (message.type) {
            case 'authenticate':
                if (message.payload) {
                    var copy = { ...context };
                    copy.connected = true;
                    setContext(copy);

                    if (handlers?.onConnected) {
                        handlers?.onConnected({
                            send: (o) => {
                                send(JSON.stringify(o));
                            },
                            message: (a, b) => message(a, b)
                        });
                    }
                } else {
                    Modal.open(<ErrorModal title='Connection failed'>
                        <FluxNotice type='error' title='Authentication failed' description={'Your session is expired, please logout and login again!'} />
                    </ErrorModal>);
                }
                break;
            case 'new':
                if (props.onNewTicket) {
                    props.onNewTicket(message.payload);
                }
                if (handlers?.onNewTicket) {
                    handlers.onNewTicket(message.payload);
                }
                break;
            case 'overAsk':
                if (props.onOverAsk) {
                    props.onOverAsk(message.payload);
                }

                if (handlers?.onOverAsk) {
                    handlers.onOverAsk([message.payload]);
                }
                break;
            case 'close-ticket':
                if (props.onCloseTicket) {
                    props.onCloseTicket(message.payload);
                }

                if (handlers?.onCloseTicket) {
                    handlers.onCloseTicket(message.payload);
                }
                break;
            case 'cashout-ticket':
                if (props.onCashoutTicket) {
                    props.onCashoutTicket(message.payload);
                }


                if (handlers?.onCashoutTicket) {
                    handlers.onCashoutTicket(message.payload);
                }
                break;
            case 'manual-settlement-ticket':
                if (props.onManualSettlement) {
                    props.onManualSettlement(message.payload);
                }


                if (handlers?.onManualSettlement) {
                    handlers.onManualSettlement(message.payload);
                }
                break;
            case 'score':
                if (props.onScore) {
                    props.onScore(message.payload);
                }


                if (handlers?.onScore) {
                    handlers.onScore(message.payload);
                }
                break;
            case 'auto-reject':
                if (props.onAutoReject) {
                    props.onAutoReject(message.payload);
                }


                if (handlers?.onAutoReject) {
                    handlers.onAutoReject(message.payload);
                }
                break;

            case 'offer-accepted':
                if (props.onOfferAccepted) {
                    props.onOfferAccepted(message.payload);
                }


                if (handlers?.onOfferAccepted) {
                    handlers.onOfferAccepted(message.payload);
                }
                break;

            case 'offer-rejected':
                if (props.onOfferRejected) {
                    props.onOfferRejected(message.payload);
                }


                if (handlers?.onOfferRejected) {
                    handlers.onOfferAccepted(message.payload);
                }
                break;
            default:
                console.log(message);
                break;
        }
    };

    return <BetMonitorSocketContext.Provider value={context}>
        {props.children}
    </BetMonitorSocketContext.Provider>;
};

BetMonitorSocket.propTypes = {
    children: PropTypes.node,
    endpoint: PropTypes.string,
    type: PropTypes.string,
    onConnected: PropTypes.func,
    onOverAsk: PropTypes.func,
    onNewTicket: PropTypes.func,
    onScore: PropTypes.func,
    onRemoveTicket: PropTypes.func,
    onCloseTicket: PropTypes.func,
    onCashoutTicket: PropTypes.func,
    onManualSettlement: PropTypes.func,
    onAutoReject: PropTypes.func,
    onOfferAccepted: PropTypes.func,
    onOfferRejected: PropTypes.func
};