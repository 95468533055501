import React from 'react';
import { appHistory } from '../../..';
import ctrlKeyManager from '../../../state/services/CtrlKeyManager';

export const gotoProfile = (id, isAffiliate = null) => {
    ctrlKeyManager.init();

    const isCtrlPressed = ctrlKeyManager.getCtrlKeyStatus();
    if (isCtrlPressed) {
        const url = (window.user.AffiliateId > 0 || isAffiliate)
            ? '/AffiliatePlayers/profile?&id=' + id
            : '/players/search?&id=' + id;

        window.open(url, '_blank');
    } else {
        if ((window.user.AffiliateId > 0 || isAffiliate)) {
            return appHistory.push('/AffiliatePlayers/profile?&id=' + id);
        } else {
            return appHistory.push('/players/search?&id=' + id);
        }
    }
};

export const openProfile = (username, id) => {
    return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
        gotoProfile(id);
    }}>{username}</a>;
};