export const TransactionTypes = {
    0: 'Deposit',
    1: 'Withdraw',
    2: 'Win',
    3: 'Bet',
    4: 'Cancel',
    30: 'Inventory Expired'
};

export const BonusTransactionTypes = {
    0: 'Bonus Created',
    1: 'Bonus Removed'
};

export const TransactionStatus = {
    0: 'Pending',
    1: 'Confirmed',
    2: 'Cancel'
};

export const GameTransactionTypes = {
    0: 'Win',
    1: 'Bet',
    2: 'Cancel'
};