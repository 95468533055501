import React from 'react';

import { Modal } from '../../../../../components/application/FluxApp';
import { FluxCard } from '../../../../../components/card/FluxCard';
import { FluxForm } from '../../../../../components/form/FluxForm';
import { InputTypes } from '../../../../../components/form/InputTypes';
import { FluxModal } from '../../../../../components/modal/FluxModal';
import { NotificationType } from '../../../../../components/notification/FluxNotification';
import { Popper } from '../../../../../components/notification/FluxPopper';
import { PromotionEndpoints } from '../../../../../lib/API/Endpoints';
import { lang } from '../../../../../lib/Localization/language';
import { PromotionCodeSearch } from './PromotionCodeSearch';

const ReuseCooldownField = {
    name: 'ReuseCooldown',
    title: 'Cooldown Period Before Reuse',
    type: InputTypes.Select,
    multiple: false,
    nullable: false,
    required: true,
    value: 0,
    values: [
        {
            title: 'One-Time Use',
            value: 0,
        },
        {
            title: '1 Day',
            value: 1,
        },
        {
            title: '2 Days',
            value: 2,
        },
        {
            title: '1 Week',
            value: 7,
        },
        {
            title: '2 Weeks',
            value: 14,
        },
        {
            title: '3 Weeks',
            value: 21,
        },
        {
            title: '1 Month',
            value: 30,
        },
        {
            title: '2 Months',
            value: 60,
        },
        {
            title: '3 Months',
            value: 90,
        }
    ]
};

const model = (promoName, promotionId, edit = false) => {
    return {
        fields: [
            {
                name: 'Id',
                hidden: true
            },
            {
                name: 'Code',
                title: 'Code',
                type: InputTypes.Text,
                required: !edit,
                readonly: edit,
                maxLength: 16,
                placeholder: 'Min. 3 - Max. 16 character'
            },
            {
                name: 'Quantity',
                title: 'Consume Quantity',
                type: InputTypes.Number,
                max: 1000,
                min: 1,
                required: true,
                placeholder: 'How many time this code can be consumed'
            },
            {
                name: 'CanConsumeDuringRegister',
                title: 'Can be consumed during registration',
                type: InputTypes.Bool
            },
            {
                name: 'Amount',
                title: 'Amount',
                type: InputTypes.Number,
                required: true
            },
            {
                name: 'Currency',
                type: InputTypes.Select,
                required: true,
                readOnly: edit,
                data: {
                    source: 'enabledCurrencies',
                    key: 'CurrencyCode',
                    title: ['Name'],
                    customTitle: (row) => {
                        return `(${row.CurrencyCode}) - ${row.Name}`;
                    },
                }
            },
            {
                name: 'PromotionId',
                hidden: true,
                value: promotionId,
                type: InputTypes.Number,
                required: true,
            },
            {
                name: 'PromotionName',
                hidden: true,
                value: promoName,
                required: true,
            },
            ReuseCooldownField
        ]
    };
};

export const EditPromotionCodePopup = (promotion, data) => {
    Modal.open(<FluxModal title='Edit Promotion Code'>
        <FluxForm
            data={data}
            columns={3}
            endpoint={PromotionEndpoints.PromotionCodeCreate}
            model={model(promotion.Name, promotion.Id, true)}
            onCancel={() => Modal.close()}
            onSubmitComplete={() => {
                Modal.close();
                Popper.pop(NotificationType.Information, null, lang('Promotion Code updated.'));
            }}
        />
    </FluxModal>);
};

export const PromotionCodeCreateSettings = (props) => {
    const { promotion } = { ...props };

    if (!promotion || promotion.Id == 0) return <></>;
    return <flex className='vertical gap-20'>
        <FluxCard title={'Promotion Code'}>
            <flex className='vertical gap-10'>
                <FluxForm
                    model={model(promotion.Name, promotion.Id)}
                    columns={3}
                    endpoint={PromotionEndpoints.PromotionCodeCreate}
                    confirm={true}
                    confirmTitle={'Please confirm'}
                    confirmMessage={'Confirm creating code'}
                    onSubmitComplete={() => {
                        Popper.pop(NotificationType.Success, lang('Code generated.'));
                    }}
                />
            </flex>
        </FluxCard>
        <PromotionCodeSearch {...props} />
    </flex>;
};