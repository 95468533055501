/* eslint-disable react/display-name */
import './_betmonitor-risk-levels.scss';
import React, { useEffect, useState } from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { lang } from '../../../lib/Localization/language';
import { LiabilityEventMonitor } from './LiabilityEventMonitor/LiabilityEventMonitor';
import { BetTicker } from './BetTicker/BetTicker';
import { BetMonitorSocket } from './MonitorTickets/BetmonitorSocket';
import { BetMonitor } from './BetMonitor/BetMonitor';
import { SearchUnsettledTickets } from './SettlementComponents/UnsettledTickets/SearchUnsettledTickets';
import { SearchPlayerBetHistory } from '../../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
//import { ConfigureRiskEvents } from './ConfigureEvents/ConfigureRiskEvents';





export const LabelValue = (props) => {
    const { label, value } = { ...props };
    return <div className='flex gap-5'>
        <label>{lang(label)}</label>
        <span>{value}</span>
    </div>;
};




export const BetMonitorRiskLevels = () => {
    const [tabButtons, setTabButtons] = useState([]);
    const [newTickets, setNewTickets] = useState(0);
    const [overAskTickets, setOverAskTickets] = useState(0);

    useEffect(() => {
        setTabButtons([
            {
                name: 'ticker',
                title: 'Bet Ticker'
            },
            {
                name: 'bet-monitor',
                title: 'Bet Monitor '
            },
            {
                name: 'event-liability',
                title: 'Event Liabilities '
            },
            {
                name: 'investigated-tickets',
                title: 'Investigated Tickets '
            }
        ]);
    }, []);


    useEffect(() => {
        updateTabButtons();
    }, [newTickets, overAskTickets]);


    const updateTabButtons = () => {
        setTabButtons([
            {
                name: 'ticker',
                title: `Bet Ticker ${newTickets ? `(${newTickets})` : ''}`
            },
            {
                name: 'bet-monitor',
                title: `Bet Monitor ${overAskTickets ? `(${overAskTickets})` : ''}`
            },
            {
                name: 'event-liability',
                title: 'Event Liabilities '
            },
            {
                name: 'unsettled-tickets',
                title: 'Unsettled tickets '
            },
            {
                name: 'investigated-tickets',
                title: 'Investigated Tickets '
            }
        ]);
    };

    if (!tabButtons.length) return <></>;
    return <React.Fragment>
        <BetMonitorSocket
            //Endpoints are being fetched from backend from now on. if you need to override, set `endpoint` prop here
            endpoint2='t{operatorId}ws.appslnd.net/ws'
            endpoint3='10.10.5.247:2121/ws'
            type='sport'
            onNewTicket={() => {
                setNewTickets(newTickets + 1);
            }}
            onCloseTicket={() => {
                setNewTickets(newTickets > 0 ? newTickets - 1 : 0);
            }}
            onRemoveTicket={() => {
                setNewTickets(newTickets > 0 ? newTickets - 1 : 0);
            }}
            onOverAsk={() => {
                setOverAskTickets(overAskTickets + 1);
            }} onAutoReject={() => {
                setOverAskTickets(overAskTickets > 0 ? overAskTickets - 1 : 0);
            }} onOfferAccepted={() => {
                setOverAskTickets(overAskTickets > 0 ? overAskTickets - 1 : 0);
            }} onOfferRejected={() => {
                setOverAskTickets(overAskTickets > 0 ? overAskTickets - 1 : 0);
            }}>
            <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons radius justify-content-center overflow-hidden'>
                <BetTicker />
                <BetMonitor />
                <LiabilityEventMonitor />
                <SearchUnsettledTickets />
                <SearchPlayerBetHistory pending />

                {/*
            <ConfigureRiskEvents />
            <ConfigureRiskEvents />
            */}
            </FluxTab>
        </BetMonitorSocket>
    </React.Fragment>;
};