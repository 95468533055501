import React, { useEffect, useState } from 'react';
import { FluxCard } from '../../../../components/card/FluxCard';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';

const timeToUseField = {
    name: 'TimeToUseAfterClaim',
    title: 'Time to Use After Claim by Players',
    type: InputTypes.Select,
    multiple: false,
    nullable: false,
    required: true,
    description: () => {
        return 'Select the time players have to use the promotion after claiming it.';
    },
    value: 30,
    values: [
        {
            title: '∞ - Infinite',
            value: 0,
        },
        {
            title: '1 Day',
            value: 1,
        },
        {
            title: '2 Days',
            value: 2,
        },
        {
            title: '1 Week',
            value: 7,
        },
        {
            title: '2 Weeks',
            value: 14,
        },
        {
            title: '3 Weeks',
            value: 21,
        },
        {
            title: '1 Month',
            value: 30,
        },
        {
            title: '2 Months',
            value: 60,
        },
        {
            title: '3 Months',
            value: 90,
        }
    ]
};

export const GeneralSettings = (props) => {
    const { promotion, disableBonusWallet, description, onChange } = { ...props };
    const [model, setModel] = useState(null);

    useEffect(() => {
        if (promotion.Type == 7 || promotion.Type == 8 || promotion.Type == 10 || promotion.Type == 11) {
            setModel({
                sections: {
                    'Bonus Information': {
                        fields: [
                            {
                                name: 'Name',
                                required: true
                            },
                            {
                                name: 'Description',
                                type: InputTypes.Textarea,
                                required: true
                            },
                            {
                                name: 'ConsumeOnlyOnce',
                                title: 'Can be consumed only once',
                                type: InputTypes.Bool,
                                required: true
                            },
                            {
                                name: 'Enabled',
                                type: InputTypes.Bool
                            }
                        ]
                    }
                }
            });

            return;
        }

        var model = {
            sections: {
                'Bonus Information': {
                    fields: [
                        {
                            name: 'Name',
                            required: true
                        },
                        {
                            name: 'Description',
                            type: InputTypes.Textarea,
                            required: true
                        },
                        {
                            name: 'ConsumeOnlyOnce',
                            title: 'Can be consumed only once',
                            type: InputTypes.Bool,
                            required: true
                        },
                        {
                            name: 'Enabled',
                            type: InputTypes.Bool
                        }
                    ]
                },
                'Wallet': {
                    fields: [
                        {
                            name: 'BonusWallet',
                            title: 'Bonus Wallet',
                            type: InputTypes.Bool,
                            readonly: disableBonusWallet
                        }
                    ]
                },
                'Date Settings': {
                    fields: [
                        {
                            name: 'StartDate',
                            title: 'Start Date',
                            type: InputTypes.DateTime
                        },
                        {
                            name: 'EndDate',
                            title: 'End Date',
                            type: InputTypes.DateTime
                        }
                    ]
                }
            }
        };

        // free bet bonus wallet settings is always true
        if (promotion.Type == 13) {
            var bonusWallet = model.sections['Wallet'].fields.find(x => x.name == 'BonusWallet');
            bonusWallet.readonly = true;
            bonusWallet.value = true;
        }

        //Expry date is enabled only for freebet promotions, if more wants to be added must be added to here
        if (promotion.Type == 13 || promotion.Type == 17) {
            model.sections['Wallet'].fields.push(timeToUseField);
        }
        setModel(model);

    }, []);

    if (!model) return <></>;


    return <flex className='vertical gap-10'>
        {description ? <FluxCard title='How it works?'>{description}</FluxCard> : null}
        <FluxCard>
            <FluxForm data={promotion} model={model} columns={2} buttons={null} onChange={onChange} />
        </FluxCard>
    </flex>;
};