/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from '../../../../components/Controls/DateTime/DateTime';
import { FluxAction } from '../../../components/application/FluxAction';
import { Modal } from '../../../components/application/FluxApp';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { Popper } from '../../../components/notification/FluxPopper';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { API } from '../../../lib/API/Api';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { lang } from '../../../lib/Localization/language';


const ModelPlayerWithFreeSpin = {
    name: 'search-players-with-free-spin',
    fields: [
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            between: true,
            value: {
                start: DateHelpers.yesterday(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number
        },
        {
            name: 'Code',
            type: InputTypes.Text,
            exact: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Name',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Surname',
            type: InputTypes.Text,
            exact: false
        },
        {
            name: 'Consumed',
            type: InputTypes.Select,
            values: {
                false: 'Not consumed',
                true: 'Consumed'
            },
            nullable: true,
            exact: true,
            value: null
        },
        {
            name: 'Enabled',
            type: InputTypes.Bool,
            exact: true,
            value: true
        }
    ]
};

export const ResultModel = {
    fields: [
        {
            name: 'EntryDate',
            title: 'Created',
            type: InputTypes.DateTime,
            render: (val) => {
                return <DateTime date={val} />;
            }
        },
        {
            name: 'Admin',
            title: 'Admin',
            type: InputTypes.Text
        },
        {
            name: 'ConsumedAt',
            title: 'Consumed Date',
            type: InputTypes.DateTime,
            render: (val, row) => {
                if (!row.Consumed) return lang('-');
                return <DateTime date={row.ConsumedAt} />;
            }
        },
        {
            name: 'Provider',
            type: InputTypes.Text
        },
        {
            name: 'Game',
            type: InputTypes.Text
        },
        {
            name: 'FreeSpinCount',
            title: 'Free Spin'
        },
        {
            name: 'BetLevel',
            title: 'Bet'
        },
        {
            name: 'Group',
            title: 'Player Group'
        },
        {
            name: 'Code',
            type: InputTypes.Text,
        },
        {
            name: 'RetailName',
            title: 'Retail Name'
        },
        {
            name: 'Affiliate',
            title: 'Affiliate'
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'Phone',
            type: InputTypes.Text,
            hidden: true
        },
        {
            name: 'TotalWin',
            title: 'Total Win',
            type: InputTypes.Number,
            render: (val, data) => {
                return <Currency amount={val} sign={data.Currency} />;
            }
        }
    ]
};

export const SearchPlayersWithFreeSpin = (props) => {
    const { player } = { ...props };
    const user = useSelector((state) => state.user).loginInformation;


    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };

    const onActionClicked = (action, item, context) => {

        switch (action.type) {
            case ActionTypes.PlayerProfile:
                gotoProfile(item.PlayerId);
                break;
            case ActionTypes.RemoveFreeSpin:
                Modal.open(<ConfirmationModal title='Remove Free Spin' confirmTitle='Remove' onConfirm={() => {
                    API.post(`${PlayerEndpoints.RemoveFreeSpin}/${item.ProviderFreeSpinId}/${item.PlayerId}/${item.GameId}`, null, 'Please wait removing free spin', 'Unable to remove free spin!').then(() => {
                        var data = context.tableContext.getData();
                        data = data.filter(x => x.Id != item.Id);
                        context.tableContext.setData(data);
                        Popper.pop('information', 'Free spin removed');
                    });
                }}>
                    <FluxNotice type='information' title='Please confirm removing the free spin.' />
                </ConfirmationModal>);
                break;
            default:
                console.log(action.type);
                break;
        }
    };



    return <FluxAction action={ActionTypes.SearchPlayersWithFreeSpin} checkActive={false}>
        <FluxSearch
            model={ModelPlayerWithFreeSpin}
            onPrepareModel={(model) => {
                if (player) {
                    model.fields = model.fields.filter(x => x.name == 'Enabled' || x.name == 'Consumed' || x.name == 'EntryDate');
                    model.fields = [{
                        name: 'PlayerId',
                        title: 'Player Id',
                        value: player.id,
                        readonly: true
                    }].concat(model.fields);
                }
                return model;
            }}
            resultModel={ResultModel}
            endpoint={PlayerEndpoints.SearchPlayersWithFreeSpin}
            actions={ActionTypes.SearchPlayersWithFreeSpin}
            title={'Player Free Spins'}
            exportToExcel={isExportEnabled('ExportPlayers')}
            exportAllData={isExportEnabled('ExportPlayers')}
            onActionClicked={onActionClicked.bind(this)} />
    </FluxAction>;
};
