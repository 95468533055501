import './_menu.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../icon/Icon';
import { appHistory } from '../../..';
import { useDispatch, useSelector } from 'react-redux';
import { hasAccess } from '../../lib/ActionManager/FluxActions';
import { lang } from '../../lib/Localization/language';
import ctrlKeyManager from '../../../state/services/CtrlKeyManager';


export const MenuItem = (props) => {
    const notifications = useSelector((state) => state.data.menuNotifications);
    const [location, setLocation] = useState(window.location.pathname);
    const [open, toggle] = useState(false);
    const [active, setActive] = useState(false);
    const [routing, setRouting] = useState(false);
    const dispatch = useDispatch();
    if (!hasAccess(props.action.type, props.action)) return <></>;

    useEffect(() => {
        setLocation(window.location.pathname);
    }, []);

    useEffect(() => {
        if (!location) return;
        var locationData = window.location.pathname.toLowerCase().split('/').filter(x => x != '');
        if (props.action.route && locationData.find(x => x == props.action.route.toLowerCase())) {
            if ((props.parentRoute?.length || locationData[0] == props.action.route.toLowerCase()) && window.location.href.indexOf(props.parentRoute) >= 0) {
                toggle(true);
                setRouting(true);
            } else {
                setRouting(false);
            }
        } else {
            setRouting(false);
        }
        if (props.action.route && locationData.reverse()[0] == props.action.route.toLowerCase()) {
            if (props.parentRoute && window.location.href.indexOf(props.parentRoute) >= 0) {
                setActive(true);
            } else {
                setActive(false);
            }
        } else {
            setActive(false);
        }
    }, [location]);

    useEffect(() => {

    }, [open]);

    useEffect(() => {
        if (active) {
            dispatch({ type: 'SELECTED_MENU_ACTION', payload: props.action });
        }
    }, [active]);


    useEffect(() => {
        if (location != window.location.pathname) setLocation(window.location.pathname);
    });

    const renderChildren = () => {
        if (!props.action.children) return;
        return <div className='child-items'>
            {props.action.children.filter(x => !x.hidden).map(x => <MenuItem key={x} action={x} parentRoute={`${props.parentRoute}/${props.action.route}`} />)}
        </div>;
    };

    const navigate = () => {
        if (props.action.execute) {
            return props.action.execute();
        }
        if (!props.action.route) return;
        ctrlKeyManager.init();

        const isCtrlPressed = ctrlKeyManager.getCtrlKeyStatus();
        if (isCtrlPressed) {
            window.open(`${props.parentRoute}/${props.action.route}`, '_blank');
        } else {
            appHistory.push(`${props.parentRoute}/${props.action.route}`);
        }
    };

    const renderNotifications = () => {
        if (!notifications) return;
        var total = 0;
        if (props.action.children) {
            Object.keys(notifications).map(x => {
                var found = props.action.children.find(y => y.type == x);
                if (found) {
                    total += notifications[x];
                }
            });

        } else {
            if (notifications[props.action.type]) {
                total = notifications[props.action.type];
            }
        }

        if (total) {
            return <badge>{total}</badge>;
        }

    };

    return <React.Fragment>
        <div className={`item${active ? ' active' : ''}${routing ? ' routing' : ''}`} onClick={() => props.action.children ? toggle(!open) : navigate()}>
            <div className='menu-icon'>
                <Icon icon={props.action.icon} type='fa-light' />
            </div>
            <span>{lang(props.action.title)}</span>
            <flex className='align-right gap-5'>
                {renderNotifications()}
                {props.action.children && <Icon icon={open ? 'angle-down' : 'chevron-left'} size='xs' />}
                <Icon icon={'chevron-right'} type='fa-light' className={`align-right active-chevron-icon${active ? ' ' : ' opacity-0'}`} size='xs' />
            </flex>
        </div>
        {open && renderChildren()}
    </React.Fragment>;
};

MenuItem.defaultProps = {
    parentRoute: ''
};

MenuItem.propTypes = {
    action: PropTypes.object,
    parentRoute: PropTypes.string
};
