/* eslint-disable react/display-name */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { InputTypes } from '../../../components/form/InputTypes';
import { PlayerVerificationType } from '../../../../components/Centrum/_models/ModelEnums';
import { UploadedDocument } from '../../../components/player/UploadedDocument';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { ActionTypes } from '../../../lib/ActionManager/FluxActions';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { FluxSearch } from '../../../components/search/FluxSearch';
import ReactSelect from 'react-select';
import { API } from '../../../lib/API/Api';
import { Endpoints } from '../../../../components/Application/Application';
import { Modal } from '../../../components/application/FluxApp';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { lang } from '../../../lib/Localization/language';
import { Popper } from '../../../components/notification/FluxPopper';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { NotificationType } from '../../../components/notification/FluxNotification';


const UploadedDocumentKey = {
    Address: 'address',
    Id: 'id'
};


const searchModel = {
    fields: [
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'IdNumber',
            display: 'Id / Passport',
            type: InputTypes.Text,
        },
        {
            name: 'Email',
            display: 'Email',
            type: InputTypes.Text,
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.Text,
        },
        {
            name: 'Balance',
            type: InputTypes.Number,
            max: 32
        },
        {
            name: 'IdStatus',
            display: 'Id / Passport Status',
            type: InputTypes.Select,
            nullable: true,
            values: PlayerVerificationType
        },
        {
            name: 'AddressStatus',
            display: 'Address Status',
            type: InputTypes.Select,
            nullable: true,
            values: PlayerVerificationType
        },
        {
            name: 'EmailStatus',
            display: 'Email Status',
            type: InputTypes.Select,
            nullable: true,
            values: PlayerVerificationType
        },
        {
            name: 'PhoneStatus',
            display: 'Phone Status',
            type: InputTypes.Select,
            nullable: true,
            values: PlayerVerificationType
        }
    ]
};



export const VerificationStatus = (props) => {
    const { value, row, type } = { ...props };

    const options = Object.keys(PlayerVerificationType).map(x => {
        return { value: x, label: PlayerVerificationType[x] };
    });



    const setStatus = () => {

    };

    const updateStatus = (newStatus) => {
        let notes = '';
        var message = 'Do you want to set status to unverified?';
        switch (newStatus) {
            case 1:
                message = 'Do you want to set status to pending?';
                break;
            case 2:
                message = 'Do you want to reject?';
                break;
            case 3:
                message = 'Do you want to verify?';
                break;
        }


        Modal.open(<ConfirmationModal title={lang('Please confirm')}
            onConfirm={
                () => {
                    API.post(`${Endpoints.UploadedDocuments}${row.Id}/${newStatus}/${type}`, {
                        notes: notes
                    }, 'Please wait', 'Operation failed').then(() => {
                        setStatus(newStatus);
                    }).catch(() => {

                    });
                }
            }>
            {lang(message)}
            <div className='flex vertical gap-5'>
                <label>{lang('Notes')}</label>
                <textarea onChangeCapture={(e) => {
                    notes = e.target.value;
                }}></textarea>
            </div>
        </ConfirmationModal >);
    };


    return <flex className='gap-10'>
        <ReactSelect
            className='react-select'
            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
            menuPortalTarget={document.body}
            defaultValue={options.find(x => x.value == value)}
            options={options}
            onChange={(e) => {
                updateStatus(parseInt(e.value));
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            isClearable={false}
            isSearchable={true}
        />
    </flex>;
};



export const TogglePlayerVerify = (props) => {
    const { value, row } = { ...props };

    const options = [
        { value: false, label: 'Not Verified' },
        { value: true, label: 'Verified' },
    ];




    const updateStatus = (newStatus) => {
        if (newStatus) {
            Modal.open(<ConfirmationModal confirmTitle='Confirm' title='Please confirm' onConfirm={() => {
                API.post(PlayerEndpoints.Verify(row.Id), null, 'Please wait', 'Unable to verify').then(() => {
                    Popper.pop(NotificationType.Success, lang('Player is verified.'));
                });
            }}>
                <FluxNotice type='information' title='Player will be verified! Please click confirm to continue!' />
            </ConfirmationModal>);

        } else {
            Modal.open(<ConfirmationModal confirmTitle='Confirm' title='Please confirm' onConfirm={() => {
                API.post(PlayerEndpoints.Unverify(row.Id), null, 'Please wait', 'Unable to unverify').then(() => {
                    Popper.pop(NotificationType.Success, lang('Player is unverified.'));
                });
            }}>
                <FluxNotice type='information' title='Player will be unverified! Please click confirm to continue!' />
            </ConfirmationModal>);
        }
    };


    return <flex className='gap-10'>
        <ReactSelect
            className='react-select'
            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
            menuPortalTarget={document.body}
            defaultValue={options.find(x => x.value == value)}
            options={options}
            onChange={(e) => {
                updateStatus(e.value);
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            isClearable={false}
            isSearchable={true}
        />
    </flex>;
};


export const searchResultModel = {
    fields: [
        {
            name: 'Group',
            display: 'Player Group'
        },
        {
            name: 'Id',
            max: 5,
            type: InputTypes.Number,
            identity: true,
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Name',
            type: InputTypes.Text
        },
        {
            name: 'Surname',
            type: InputTypes.Text
        },
        {
            name: 'IdNumber',
            display: 'Id / Passport',
            type: InputTypes.Text,
        },
        {
            name: 'Email',
            display: 'Email',
            type: InputTypes.Text,
        },
        {
            name: 'Tag',
            display: 'Tags',
            type: InputTypes.Text,
        },
        {
            name: 'Balance',
            type: InputTypes.Number,
            max: 32
        },
        {
            name: 'IdStatus',
            title: 'Id / Passport Status',
            type: InputTypes.Select,
            render: (val, row) => {
                return <VerificationStatus key={row.Id + '_id'} value={val} row={row} type='id' />;
            },
            values: PlayerVerificationType
        },
        {
            name: 'AddressStatus',
            title: 'Address Status',
            type: InputTypes.Select,
            render: (val, row) => {
                return <VerificationStatus key={row.Id + '_address'} value={val} row={row} type='address' />;
            },
            values: PlayerVerificationType
        },
        {
            name: 'EmailStatus',
            title: 'Email Status',
            type: InputTypes.Select,
            render: (val, row) => {
                return <VerificationStatus key={row.Id + '_email'} value={val} row={row} type='email' />;
            },
            values: PlayerVerificationType
        },
        {
            name: 'PhoneStatus',
            title: 'Phone Status',
            type: InputTypes.Select,
            render: (val, row) => {
                return <VerificationStatus key={row.Id + '_phone'} value={val} row={row} type='phone' />;
            },
            values: PlayerVerificationType
        },
        {
            name: 'Verified',
            title: 'Verifcation Status',
            type: InputTypes.Select,
            render: (val, row) => {
                return <TogglePlayerVerify key={row.Id + '_verfied'} value={val} row={row} />;
            }
        }
    ]
};


export const PlayerVerifications = () => {
    const [selectedPlayer, selectPlayer] = useState(null);
    const user = useSelector((state) => state.user).loginInformation;
    const verifications = useSelector((state) => state.data.verifications);


    const isExportEnabled = (target) => {
        var actions = user.UserPrivileges?.SpecialActions;
        if (!actions) return false;
        if (actions.find(x => x == target)) {
            return true;
        }
        return false;
    };


    const onActionClicked = (action, player) => {

        switch (action.type) {
            case ActionTypes.ViewPlayerVerification:
                selectPlayer(player);
                break;
            case ActionTypes.PlayerProfile:
                gotoProfile(player.Id);
                break;
        }
    };
    const onRenderPlayerSearchActions = () => {

    };

    if (selectedPlayer) {
        return <FluxCard key={selectedPlayer}>
            <flex className='vertical gap-10'>
                <flex className='gap-5'>
                    <label>Player documents</label><span>{selectedPlayer.Username}</span>
                    <Button className='align-right' title='Close' onClick={() => selectPlayer(null)} />
                </flex>
                <flex className='vertical gap-10'>
                    <FluxCard title="Uploaded Address Documents">
                        <UploadedDocument PlayerId={selectedPlayer.Id} Status={selectedPlayer.AddressStatus} DocumentKey={UploadedDocumentKey.Address} />
                    </FluxCard>
                    <FluxCard title="Uploaded Id Documents">
                        <UploadedDocument PlayerId={selectedPlayer.Id} Status={selectedPlayer.IdStatus} DocumentKey={UploadedDocumentKey.Id} />
                    </FluxCard>
                </flex>
            </flex>
        </FluxCard>;
    }
    return <FluxSearch key='search'
        model={searchModel}
        exportToExcel={isExportEnabled('ExportPlayers')}
        exportAllData={isExportEnabled('ExportPlayers')}
        resultModel={searchResultModel}
        data={verifications}
        actions={ActionTypes.SearchPlayerVerifications}
        onActionClicked={onActionClicked.bind(this)}
        onRenderAction={onRenderPlayerSearchActions.bind(this)}
        endpoint={PlayerEndpoints.Verficiations}
    />;
};
